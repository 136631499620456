<template>
  <v-dialog v-model="dialog" persistent width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="ml-3"
        dark
        v-bind="attrs"
        v-on="on"
        v-if="!form || !form.id"
      >
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-btn color="primary" :icon="!btn" dark v-bind="attrs" v-on="on" v-else>
        <v-icon>mdi-circle-edit-outline</v-icon></v-btn
      >
    </template>

    <v-card>
      <v-toolbar color="#365d84" flat dark>
        <v-toolbar-title
          ><span v-if="!form || !form.id">Новая тема (раздел)</span
          ><span v-else>Изменить тему (раздел)</span></v-toolbar-title
        ><v-spacer></v-spacer>

        <v-btn :disabled="saving" @click="dialog = false" light
          ><v-icon>mdi-close</v-icon> Отменить</v-btn
        ><v-btn :loading="saving" color="green" class="mx-3" @click="save()"
          ><v-icon>mdi-check</v-icon> Сохранить</v-btn
        >
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-text-field
          v-model="form.name"
          :error="errors.name ? true : false"
          :error-messages="errors.name ? errors.name : []"
          outlined
          :counter="255"
          :rules="rules.required"
          label="Название"
          required
        ></v-text-field>
        <v-text-field
          type="number"
          v-model="form.vol"
          :error="errors.vol ? true : false"
          :error-messages="errors.vol ? errors.vol : []"
          :max="100"
          :min="0"
          outlined
          oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} if(Number(this.value) < Number(this.min)){this.value = this.min;}"
          :rules="rules.required"
          label="Аудиторная работа (часов):"
          required
        ></v-text-field>
        <v-text-field
          type="number"
          v-model="form.self"
          :error="errors.self ? true : false"
          :error-messages="errors.self ? errors.self : []"
          :max="100"
          :min="0"
          outlined
          oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} if(Number(this.value) < Number(this.min)){this.value = this.min;}"
          :rules="rules.required"
          label="Самостоятельная подготовка (часов):"
          required
        ></v-text-field>
        <v-select
          :items="periods"
          v-model="form.studyPeriodNum"
          :error="errors.studyPeriodNum ? true : false"
          :error-messages="errors.studyPeriodNum ? errors.studyPeriodNum : []"
          outlined
          item-text="order"
          item-value="order"
          label="Период обучения"
        >
          <template v-slot:selection="data">
            <b>Курс {{ data.item.course }}</b
            >. Период: {{ data.item.order }}.
          </template>
          <template v-slot:item="data">
            <b>Курс {{ data.item.course }}</b
            >. Период: {{ data.item.order }}.
          </template>
        </v-select>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["topic", "disciplineId", "periods", "btn"],
  data() {
    return {
      dialog: false,
      saving: false,
      form: {},
      rules: {
        required: [(v) => !!v || "Не должно быть пустым"],
      },
      errors: {},
    };
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.form = { ...this.topic };
      }
    },
  },
  mounted() {
    this.form = { ...this.topic };
  },
  methods: {
    save() {
      this.saving = true;
      if (!this.form.id) {
        this.$http
          .post(
            "disciplines/topic-create?disciplineId=" + this.disciplineId,
            this.form
          )
          .then((response) => {
            this.$emit("create", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.$http
          .post(
            "disciplines/topic-update?topicId=" +
              this.topic.id,
            this.form
          )
          .then((response) => {
            this.$emit("update", response.data);
            this.dialog = false;
          })
          .catch((e) => {
            if (e.response.data.errors) {
              this.errors = e.response.data.errors;
            } else {
              alert("Возникла непредвиденная ошибка");
            }
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>