<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="error">mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 red lighten-2 white--text">
        {{ title }}
      </v-card-title>

      <v-card-text class="text-subtitle-1 font-weight-bold text-center pt-3">
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false" :disabled="deleting"> Отменить </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="remove()" :loading="deleting"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'text', 'actionHref'],
  data: () => ({
    dialog: false,
    deleting: false
  }),
  methods: {
    remove(force = 0) {
      this.deleting = true
      this.$http.get(this.actionHref, {
        params: {
          force: force
        }
      }).then(() => {
        this.dialog = false
        this.$emit('remove')
      }).catch((e) => {
        if (e.response) {
          if (e.response.status == 449) {
            if (confirm(e.response.data)) {
              this.remove(1)
            }
          }
          else{
alert('Ошибка!')
          }
        }
        
      }).finally(() => {
        this.deleting = false
      })
    }
  }
}
</script>

<style>
</style>