<template>

  <v-card elevation="0" class="w-100 ma-0 rounded-0 h-100 d-flex flex-column" color="rgb(255 255 255 / 80%)">
    <v-toolbar color="rgb(90 126 161)" flat dark>
      <v-toolbar-title>Дисциплины</v-toolbar-title>
      <v-spacer></v-spacer>
      
      <editBtn :discipline="{}" :eduProgId="eduProgId" @create="disciplines.push($event)" />

    </v-toolbar>
    <v-divider></v-divider>
    <v-text-field class="ma-3" v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
    </v-text-field>
    <v-card-text v-if="!loading" class="h-100">
      <v-expansion-panels >
        <template v-for="item of disciplines">


          <v-expansion-panel :key="item.id" readonly>
        <v-expansion-panel-header >
          
          <v-row>
            <v-col class="pa-0">
                <div class="font-weight-medium py-0 pl-0 pr-3">{{ item.name }}</div>
                <v-chip v-if="(!item.disciplineTopics || item.disciplineTopics.length == 0) && 1!==1" color="red" small dark
                  style="max-width: max-content" class="my-1">Нет тем</v-chip>
                <v-chip v-if="(!item.file_doc || !item.file) && 1!==1 " color="red" small dark
                  style="max-width: max-content" class="ma-1">Не загружены оценочные стредства</v-chip>
            </v-col>
            <v-col class="py-0">
              <div class="text-right mt-1">
                <editBtn :discipline="item" :btn="false" :eduProgId="eduProgId" @update="disciplineUpdate(item.id,$event)"/>
                <deleteBtn title="Удалить"
                  :text="'При удалении дисциплины вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить дисцеплину: '+ item.name"
                  :actionHref="'disciplines/delete?id='+item.id"
                  @remove="disciplines.splice(disciplines.findIndex(el=>el.id===item.id),1)" />
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <topics :disciplineId="item.id" :periods="periods" @update="disciplineUpdate(item.id,{disciplineTopics:$event})"></topics>
        </v-expansion-panel-content>
      </v-expansion-panel>



          <v-divider :key="'d' + item.id" />
        </template>
      </v-expansion-panels>
    </v-card-text>

  </v-card>
</template>


<script>


import editBtn from '@/components/eduProgDisciplines/edit.vue'
import topics from "@/components/eduProgDisciplines/topics/list.vue";
import deleteBtn from '@/components/delete.vue'
export default {
  name: "StarterPage",
  props: ["eduProgId"],
  data: () => ({
    disciplines: [],
    periods:[],
    loading: true,
    search: "",
    headers: [
      { text: "Название", value: "name" },
      { text: "Действия", value: "actions" },
    ],
  }),
  components: {
    deleteBtn,
    topics,

    editBtn
  },

  methods: {
    disciplineUpdate(id,event){
      let discipline = this.disciplines.find(el=>el.id===id)
      Object.assign(discipline,event)
    },
    fecthStructure(){
    
      this.$http
        .get("edu-prog/structure?eduProgId=" + this.eduProgId)
        .then((response) => {
          this.periods = response.data;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })


    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("disciplines/list?eduProgId=" + this.eduProgId)
        .then((response) => {
          this.disciplines = response.data.disciplines;
        })
        .catch(() => {
          alert("Возникла ошибка");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fecthStructure()
    this.fetchData();
  },
};
</script>