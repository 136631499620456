<template>
  <v-dialog v-model="dialog" fullscreen scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-transit-connection-variant</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-toolbar-title>Связь тем дисциплины </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" light>
          <v-icon>mdi-close</v-icon> Закрыть
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-sheet outlined rounded class="px-3" style="border-bottom: none" elevation="1">
              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-checkbox v-model="showAll" label="Показывать все темы"></v-checkbox>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-select label="Тема" v-if="!showAll" v-model="topicToshow" :items="discipline.disciplineTopics"
                    item-text="name" item-value="id"></v-select>
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet outlined elevation="3" class="pl-3 py-3" style="height: calc(100vh - 175px); overflow-y: scroll">
              <v-simple-table>
                <tbody>
                  <tr v-for="topic in filterdTopics" :key="topic.id">
                    <td style="width: 30%">{{ topic.name }}</td>
                    <td>
                      <v-sheet outlined class="py-3 my-3">

                        <draggable v-bind="dragOptions" v-model="leftSide[topic.id]" @end="dragEnd"
                          style="min-height: 30px; height: 100%" :group="{
                            name: 'skillsKnowledges',
                            pull: false,
                            put: true,
                          
                          }" @change="onSkillsKnowledgesChange(topic.id, $event)">
                          <v-sheet v-for="skillKnowledge in leftSide[topic.id]"
                            :key="skillKnowledge.type + '_' + skillKnowledge.id + '_' + skillKnowledge.custom" rounded
                            outlined elevation="3" style="cursor: pointer"
                            v-bind:class="[{ 'green': skillKnowledge.type == 'skill' }, { 'blue': skillKnowledge.type == 'knowledge' }]"
                            class=" lighten-4 pa-1 ma-1 text-body-2">
                            <v-row>
                              <v-col>
                                <b v-if="skillKnowledge.type == 'skill'">У:</b>
                                <b v-if="skillKnowledge.type == 'knowledge'">З:</b> {{ skillKnowledge.name }}
                              </v-col>
                              <v-col cols="auto">
                                <v-tooltip bottom >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon color="red" @click.stop="
                                      removeSkillsKnowledge(
                                        topic.id,
                                        skillKnowledge.type,
                                        skillKnowledge.id,
                                        skillKnowledge.custom
                                      )
                                    ">
                                      <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <div style="width:600px" v-if="!skillKnowledge.custom">
                                    <v-alert class="ma-0 my-3" dense border="left" colored-border color=" accent-4"
                                      elevation="3">
                                      <div class="text-subtitle-2 pa-1" :key="comp.id"
                                        v-for="comp in getCompetencies(skillKnowledge.workAction.workFunc.id)">
                                        <b>{{ comp.type }}:</b> {{ comp.name }}
                                      </div>
                                    </v-alert>
                                    <v-alert class="ma-0 my-3" dense border="left" colored-border
                                      color="deep-purple accent-4" elevation="3">
                                      <div class="text-subtitle-2 pa-3">
                                        <b>ТФ:</b> {{ skillKnowledge.workAction.workFunc.name }}
                                      </div>
                                    </v-alert>
                                    <v-alert class="ma-0 my-3" dense border="left" colored-border
                                      color="orange accent-4" elevation="3">
                                      <div class="text-subtitle-2 pa-3">
                                        <b>ТД:</b> {{ skillKnowledge.workAction.name }}
                                      </div>
                                    </v-alert>
                                  </div>
                                                            <div v-else style="width:600px">
                            <v-alert class="ma-0 my-3" dense border="left" colored-border color=" accent-4"
                              elevation="3">
                              <div class="text-subtitle-2 pa-1">
                                <b>{{ findCustomSkillCompetence(skillKnowledge.skillId).competence.type}}:</b> {{ findCustomSkillCompetence(skillKnowledge.skillId).competence.name }}
                              </div>
                            </v-alert>
                          </div>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </draggable>
                      </v-sheet>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-sheet>
          </v-col>
          <v-col>
            <v-sheet outlined elevation="3" class="pl-3">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
              </v-text-field>
              <div style="height: calc(100vh - 158px); overflow-y: scroll">
                <draggable v-bind="dragOptions" v-model="skillsKnowledgesList" @end="dragEnd"
                  style="min-height: 30px; height: 100%" :group="{
                    name: 'skillsKnowledges',
                    pull: 'clone',
                    put: false,
                  }">
                  <v-sheet v-for="skillKnowledge in filteredRightSide "
                    :key="skillKnowledge.type + '_' + skillKnowledge.id + '_' + skillKnowledge.custom" rounded outlined
                    elevation="3" style="cursor: pointer"
                    v-bind:class="[{ 'green': skillKnowledge.type == 'skill' }, { 'blue': skillKnowledge.type == 'knowledge' }, { 'lighten-2': selectedList.findIndex(el => el.id == skillKnowledge.id && el.custom == skillKnowledge.custom && el.type == skillKnowledge.type) !== -1 }]"
                    class="lighten-4 pa-1 ma-1 text-body-2">
                    <v-row>
                      <v-col>
                        <b v-if="skillKnowledge.type == 'skill'">У:</b>
                        <b v-if="skillKnowledge.type == 'knowledge'">З:</b> {{ skillKnowledge.name }}
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" dark v-bind="attrs" v-on="on">
                              mdi-information-outline
                            </v-icon>
                          </template>
                          <div style="width:600px" v-if="!skillKnowledge.custom">
                            <v-alert class="ma-0 my-3" dense border="left" colored-border color=" accent-4"
                              elevation="3">
                              <div class="text-subtitle-2 pa-1" :key="comp.id"
                                v-for="comp in getCompetencies(skillKnowledge.workAction.workFunc.id)">
                                <b>{{ comp.type }}:</b> {{ comp.name }}
                              </div>
                            </v-alert>
                            <v-alert class="ma-0 my-3" dense border="left" colored-border color="deep-purple accent-4"
                              elevation="3">
                              <div class="text-subtitle-2 pa-3">
                                <b>ТФ:</b> {{ skillKnowledge.workAction.workFunc.name }}
                              </div>
                            </v-alert>
                            <v-alert class="ma-0 my-3" dense border="left" colored-border color="orange accent-4"
                              elevation="3">
                              <div class="text-subtitle-2 pa-3">
                                <b>ТД:</b> {{ skillKnowledge.workAction.name }}
                              </div>
                            </v-alert>
                          </div>
                          <div v-else style="width:600px">
                            <v-alert class="ma-0 my-3" dense border="left" colored-border color=" accent-4"
                              elevation="3">
                              <div class="text-subtitle-2 pa-1">
                                <b>{{ findCustomSkillCompetence(skillKnowledge.skillId).competence.type}}:</b> {{ findCustomSkillCompetence(skillKnowledge.skillId).competence.name }}
                              </div>
                            </v-alert>
                          </div>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </draggable>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style >
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.highlight {
  transform: scale(1.1);
  transition: 0.5s;

}

.unhighlight {
  transform: scale(1);
  transition: 0.5s;

}
</style>
<script>
import draggable from "vuedraggable";
export default {
  name: "StarterPage",
  props: ["eduProgWorkFunctions", "disciplineId", "eduProgId"],
  data: () => ({
    dialog: false,
    competencies: [],
    loading: true,
    discipline: {},
    showAll: true,
    topicToshow: null,
    expanded: [],
    search: "",
    leftSide: {},
    drag: false,
    headers: [{ text: "Трудовая функция", value: "workFunc" }],
    skillsKnowledgesList: []
  }),
  components: {
    draggable
  },
  computed: {
    selectedList() {
      let list = [];
      for (let topicList of Object.values(this.leftSide)) {
        for (let knowledgeSkill of topicList) {
          let index = this.skillsKnowledgesList.findIndex(
            (el) => el.id == knowledgeSkill.id && el.custom == knowledgeSkill.custom && el.type == knowledgeSkill.type
          )
          if (index !== -1) {
            list.push(this.skillsKnowledgesList[index])
          }
        }
      }
      return list
    },
    filteredRightSide() {
      let filtered = JSON.parse(JSON.stringify(this.skillsKnowledgesList));

      if (!this.showAll) {
        let data = this.leftSide[this.topicToshow];
        filtered = filtered.filter(
          (el) =>
            data.findIndex(
              (selected) => el.id == selected.id && el.custom == selected.custom && el.type == selected.type
            ) === -1
        );
      }

      filtered = filtered.filter(
        (el) =>
          el.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) !== -1
      );

      return filtered;
    },
    filterdTopics() {
      if (this.showAll) {
        return this.discipline.disciplineTopics;
      } else {
        return [this.discipline.disciplineTopics.find((el) => el.id === this.topicToshow)];
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    dialog(val) {
      if (val == true) {
        this.fetchData()
      }
    },
  },
  methods: {
    findCustomSkillCompetence(skillId){
      return this.competencies.find(competence=>competence.customSkills.findIndex(skill=>skill.skillId==skillId)!==-1)
    },
    removeSkillsKnowledge(topicId,type, id, custom) {
              this.$http
          .get("connection/discipline-topics-with-skills-unconnect", {
            params: {
              topicId: topicId,
              id: id,
              type: type,
              custom: custom,
            },
          }).then(()=>{
            if(custom==true){
              let index=this.leftSide[topicId].findIndex(el=>el.custom==true && el.id==id)
              this.leftSide[topicId].splice(index,1)
            } else {
              let index=this.leftSide[topicId].findIndex(el=>el.custom==false && el.id==id && el.type==type)
              this.leftSide[topicId].splice(index,1)
            }
          })
          .catch(() => {
            alert("Ошибка");
          });


    },
    getCompetencies(workFuncId) {
      let competencies = this.competencies.filter(el => el.workFunctions.findIndex(wf=>wf.eduProgProfStandFuntion.workFuncId==workFuncId) !== -1)
      if (competencies) {
        return competencies.map(el => el.competence)
      }
    },
    onSkillsKnowledgesChange(topicId, ev) {
      if (ev.added) {
        let element = ev.added.element;
        let count = this.leftSide[topicId].filter(
          (el) => el.id == element.id && el.custom == element.custom && el.type == element.type
        ).length;
        if (count > 1) {
          this.leftSide[topicId].splice(ev.added.newIndex, 1);
          return false;
        }

        this.$http
          .get("connection/discipline-topics-with-skills-connect", {
            params: {
              topicId: topicId,
              id: element.id,
              type: element.type,
              custom: element.custom,
            },
          })
          .catch(() => {
            alert("Ошибка");
          });
      }
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("connection/discipline-topics-with-skills", {
          params: {
            disciplineId: this.disciplineId
          },
        })
        .then((response) => {
          this.competencies = response.data.competencies;
          this.discipline = response.data.discipline;
          this.topics = this.discipline.disciplineTopics
          this.topicToshow = this.discipline.disciplineTopics[0].id;
          for (let topic of this.discipline.disciplineTopics) {
            this.$set(this.leftSide, topic.id, []);
            for (let skill of topic.topicSkills) {
              let skillData = this.discipline.skills.find(el => el.skillId == skill.skillId)
              if (skillData) {
                this.leftSide[topic.id].push({ type: 'skill', custom: false, ...skillData.skill })
              }

            }
            for (let knowledge of topic.topicKnowledges) {
              let knowledgeData = this.discipline.knowledges.find(el => el.knowledgeId === knowledge.knowledgeId)
              if (knowledgeData) {
                this.leftSide[topic.id].push({ type: 'knowledge', custom: false, ...knowledgeData.knowledge })
              }
            }
            for (let custom of topic.topicCustomSkills) {

              let data = this.discipline.customSkills.find(el => el.skillId === custom.skillId)
              if (data) {
                this.leftSide[topic.id].push({ type: data.type, custom: true, ...data.skill })
              }

            }
          }
          this.skillsKnowledgesList = []
          for (let skill of this.discipline.skills) {
            this.skillsKnowledgesList.push({ type: 'skill', custom: false, ...skill.skill })
          }
          for (let knowledge of this.discipline.knowledges) {
            this.skillsKnowledgesList.push({ type: 'knowledge', custom: false, ...knowledge.knowledge })
          }
          for (let custom of this.discipline.customSkills) {
            this.skillsKnowledgesList.push({ custom: true, ...custom.skill })
          }
        })
        .finally(() => {

          this.loading = false;
        });
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    }
  },
};
</script>
