<template>
  <div>



    <v-card elevation="3" class="mt-3">
      <v-toolbar color="#365d84" flat dark style="max-height: 64px">
        <v-text-field class="ma-3" v-model="search" append-icon="mdi-magnify" label="Поиск" single-line hide-details>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" width="800">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mr-3" dark v-bind="attrs" v-on="on">
              <v-icon>mdi-timetable</v-icon>
            </v-btn>
          </template>
          <v-card elevation="3">
            <v-toolbar color="#365d84" flat dark style="max-height: 64px">
              Распределение по периодам (в часах):
              <v-spacer></v-spacer>
              <v-btn @click="dialog=false" fab small light><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>

            <v-simple-table style="width: 100%" class="text-center">
              <tbody>
                <tr class="blue lighten-5">
                  <td v-for="course in courses" :colspan="course.length" :key="course.id">
                    Курс {{ course[0].course }}
                  </td>
                </tr>
                <tr class="grey lighten-3">
                  <td v-for="period in periods" :key="period.id">
                    Период {{ period.order }}
                  </td>
                </tr>
                <tr v-if="!loading">
                  <td v-for="period in periods" :key="period.id"
                    v-bind:class="periodHours[period.order]?'orange lighten-4':''">
                    <div v-if="periodHours[period.order]" class="ma-3">
                      Аудиторная работа:<br>
                      {{periodHours[period.order].vol}}<br>
                      Самостоятельная подготовка:<br>
                      {{periodHours[period.order].self}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

          </v-card>
        </v-dialog>
        <skillsConnect :disciplineId="disciplineId" />
        <editTopic :topic="{}" :periods="periods" :disciplineId="disciplineId" @create="topics.push($event);$emit('update',topics)" />
      </v-toolbar>


      <v-data-table :items="topics" :headers="headers" :search="search" :loading="loading" :custom-filter="searchFilter"
        class="border" loading-text="Загрузка... Пожалуйста подождите" no-data-text="Результатов нет"
        no-results-text="Результатов нет" :footer-props="{
          'items-per-page-text': 'Количество',
          'items-per-page-options': [5, 10, 20],
        }">
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} из {{ itemsLength }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <editTopic :topic="item" :periods="periods" :disciplineId="disciplineId" @update="
              $set(
                topics,
                topics.findIndex((el) => el.id === item.id),
                $event
              ); 
            " />
            <deleteBtn title="Удалить" :text="
              'При удалении темы (раздела) вся информация о ней будет безвозвартано потеряна, вы действительно хотите удалить: ' +
              item.name
            " :actionHref="'disciplines/topic-delete?topicId=' + item.id" @remove="
              topics.splice(
                topics.findIndex((el) => el.id === item.id),
                1
              ); $emit('update',topics)
            " />
          </div>
        </template>
        <template v-slot:no-data> Нет элементов </template>
        <template v-slot:loading>
          <v-skeleton-loader class="mx-auto" type="list-item-two-line@1"></v-skeleton-loader>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import skillsConnect from './skillsConnection.vue'
import editTopic from "./edit.vue";
import deleteBtn from "../../delete.vue";
export default {
  props: ["disciplineId", "periods"],
  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      topics: [],
      headers: [
        { text: "Название", value: "name" },
        { text: "Аудиторная работа (часов)", value: "vol" },
        { text: "Самостоятельная подготовка (часов)", value: "self" },
        { text: "Номер периода", value: "studyPeriodNum" },
        { text: "Действия", value: "actions" },
      ],
    };
  },
  computed: {
    courses() {
      return this.groupBy(this.periods, "course");
    },
    periodHours() {
      let arr = {}
      for (let topic of this.topics) {
        if (!arr[topic.studyPeriodNum]) { arr[topic.studyPeriodNum] = { vol: 0, self: 0 } }
        arr[topic.studyPeriodNum].vol += parseInt(topic.vol)
        arr[topic.studyPeriodNum].self += parseInt(topic.self)
      }
      return arr
    }
  },
  components: {
    deleteBtn,
    editTopic,
    skillsConnect
  },
  methods: {
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    searchFilter(value, search, item) {
      return (
        item.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    fetchData() {
      this.loading = true;
      this.$http
        .get("disciplines/topics", {
          params: {
            disciplineId: this.disciplineId,
          },
        })
        .then((response) => {
          this.topics = response.data.topics;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>

</style>